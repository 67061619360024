document.addEventListener("DOMContentLoaded", () => {
  const container = document.querySelector("#banner-no-offer");
  if (!container) return;

  const banners = document.querySelectorAll(".js-banner-no-offer");
  if (banners.length === 0) return;

  banners.forEach(banner => banner.classList.add("is-hidden"));

  const randomIndex = Math.floor(Math.random() * banners.length);
  const randomBanner = banners[randomIndex];

  randomBanner.classList.remove("is-hidden");

  container.appendChild(randomBanner);
});