import Vue from "vue";
import vSelect from "vue-select";
import {loadMap} from './maptiler.js';
import fachowcy from "./fachowcy";
import Plyr from 'plyr';
import ItCategories from "./it_categories";

require('../../node_modules/plyr/dist/plyr.css');

Vue.component("v-select", vSelect);

if (document.getElementById('app-offer-erecruter')) {
    let custom_id;
    if (document.getElementById('app-offer-erecruter').dataset.id) {
        custom_id = document.getElementById('app-offer-erecruter').dataset.id;
    }

    var erecruterApp = new Vue({
        el: '#app-offer-erecruter',
        data: {
            settings: {
                url: process.env.API_URL,
                // url: 'https://api.local',
                key: process.env.API_TOKEN,
                loaderClassName: null, //'lds-ripple',
                loaderVisibleClassName: 'is-visible',
                contentClassName: 'c-results',
            },

            urlOffer: document.getElementById('app-offer-erecruter').getAttribute('data-offer-url'),

            currentOffers: [],
            offer: null,
            liked: [],
            doneRequests: 0,
            isErecruterOffer: false,
            type: null,
        },
        computed: {
            isSalesRepresentative: function () {
                return this.offer && this.offer.category.trim().toLowerCase() == 'przedstawiciel handlowy';
            },
            payFrom: function () {
                if(!this.offer) return null;
                if(this.isErecruterOffer)
                    return this.offer.pay_from;

                return this.offer.payFrom ;
            },
            payTo: function () {
                if(!this.offer) return null;
                if(this.isErecruterOffer)
                    return this.offer.pay_to;

                return this.offer.payTo ;
            },
        },
        watch: {
            currentOffers: function (newVal, oldVal) {
                if (newVal.length > 0 && this.type == 'it') {
                    document.body.classList.remove('no-offers');
                } else if (this.type == 'it') {
                    document.body.classList.add('no-offers');
                }
            }
        },
        mounted: function () {
            const queryString = window.location;

            if (this.$el.dataset && this.$el.dataset.type)
                this.type = this.$el.dataset.type;

            if (this.type == 'it') {
                this.liked = localStorage.getItem('likedit') ? JSON.parse(localStorage.getItem('likedit')) : []
            } else {
                this.liked = localStorage.getItem('liked') ? JSON.parse(localStorage.getItem('liked')) : []
            }

            let id = null;

            if (custom_id) {
                id = custom_id;
            } else if (queryString) {
                let url = new URL(queryString),
                    params = new URLSearchParams(url.search);
                id = params.get('id');
            }


            if (id) {
                this.loadOffer(id,false,()=>this.loadOffer(id,true,()=>window.location.href = window.location.href.split('/').slice(0, 3).join('/') + '/404.html'));
                // this.loadOffer(id,true)
            }

        },
        methods: {
            loadOffer: function (id,isERecruter = false,errorCallback = null) {
                let that = this;
                let apiUrlPrefix = "/api/teamtailor";
                if(isERecruter){
                    apiUrlPrefix = "/api";
                }
                this.request(apiUrlPrefix  + "/show/" + this.settings.key + "/" + id, function (data) {
                    that.offer = data;
                    that.isErecruterOffer = isERecruter;
                    //is fachowcy offer ?
                    fachowcy().forEach((item) => {

                        if (item.list.includes(that.offer.category)) {
                            that.type = 'fachowcy';
                        }

                    });

                    if (that.type == 'fachowcy' && location.pathname == '/oferta.html') {
                        window.open('/oferta-fachowcy.html?id=' + id, '_self');
                    } else if (that.type != 'fachowcy' && location.pathname == '/oferta-fachowcy.html') {
                        window.open('/oferta.html?id=' + id, '_self');
                    }

                    //is IT offer ?
                    if (ItCategories.includes(that.offer.category) && location.pathname.indexOf('/oferta.html') !== -1) {
                        let url = location.href.replace('/oferta.html', '/it-oferta.html');
                        window.open(url, '_self');
                    } else if (!ItCategories.includes(that.offer.category) && location.pathname.indexOf('/it-oferta.html') !== -1) {
                        let url = location.href.replace('/it-oferta.html', '/oferta.html');
                        window.open(url, '_self');
                    }


                    setTimeout(function () {
                        if (document.getElementsByClassName("page-search-offer")[0])
                            document.getElementsByClassName("page-search-offer")[0].classList.remove('is-hidden');
                        loadMap(true, data);
                        window.myLazyLoad.update();

                        //fachowy hero img
                        document.querySelectorAll('.js-img-fachowcy').length > 0 ? window.fachowcyImg() : false;

                    });


                    let similarUrl = apiUrlPrefix + "/similar/" + that.settings.key + "/" + id;
                    if (that.type == 'fachowcy') {
                        similarUrl += "?type=fachowcy";
                    } else if (that.type == 'it') {
                        similarUrl += "?type=it";
                    } else {
                        similarUrl += "?type=kariera";
                    }
                    that.request(similarUrl, function (data) {
                        that.currentOffers = data;
                        setTimeout(function () {
                            if (document.getElementsByClassName("c-results")[0])
                                document.getElementsByClassName("c-results")[0].classList.remove('is-hidden');
                            that.initVideo();
                        }, 100);
                    });

                }, function () {

                    if (errorCallback) {
                        errorCallback();
                    }
                });

            },
            showPreloader: function () {
                if (this.settings.loaderClassName) {
                    document.getElementsByClassName(this.settings.loaderClassName)[0].classList.add(this.settings.loaderVisibleClassName);
                }
                this.showJsCloseResults = false;
            },
            hidePreloader: function () {
                if (this.settings.loaderClassName) {
                    document.getElementsByClassName(this.settings.loaderClassName)[0].classList.remove(this.settings.loaderVisibleClassName);
                }
            },
            toggleLike: function (id) {
                if (this.liked.indexOf(id) === -1) {
                    this.liked.push(id);
                } else {
                    this.liked.splice(this.liked.indexOf(id), 1);
                }
                if (this.type == 'it')
                    localStorage.setItem('likedit', JSON.stringify(this.liked));
                else
                    localStorage.setItem('liked', JSON.stringify(this.liked));
            },
            request: function (url, callback, error404 = null) {
                var request = new XMLHttpRequest();
                var that = this;
                that.showPreloader();
                request.onreadystatechange = function () {
                    if (request.readyState === 4 && request.status === 200) {
                        that.hidePreloader();
                    } else {
                        that.showPreloader();
                    }
                }

                request.open('GET', this.settings.url + url, true);

                request.onload = function () {
                    if (this.status >= 200 && this.status < 400) {
                        callback(JSON.parse(this.response))
                    } else if (error404 && this.status === 404) {
                        error404();
                    }
                };
                request.send();
            },
            initVideo: function () {
                if (document.querySelector('#player_offer')) {
                    const player = new Plyr('#player_offer', {
                        controls: ['play', 'progress', 'volume', 'mute', 'play-large']
                    });
                }
            },
            showVideo: function (name) {
                const regex = new RegExp(`^${name}$`, 'i');
                return regex.test(this.offer.title.trim().toLowerCase());
            }
        }
    })
}

